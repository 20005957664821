// Generated by Framer (92f3d02)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/hgKudGhZ5";
import * as localizedValues from "./EXTb7_Z7k-0.js";

const cycleOrder = ["tIyMv6pT4"];

const serializationHash = "framer-C3mmn"

const variantClassNames = {tIyMv6pT4: "framer-v-tpuiza"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {Zx6v6ZVYd: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, subtitle, width, ...props}) => { return {...props, HFZZ32egY: subtitle ?? props.HFZZ32egY ?? "Product"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;subtitle?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, HFZZ32egY, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "tIyMv6pT4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-tpuiza", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"tIyMv6pT4"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1dts917"} data-styles-preset={"hgKudGhZ5"} style={{"--framer-text-color": "var(--extracted-r6o4lv, rgba(253, 250, 246, 0.5))"}}>Product</motion.p></React.Fragment>} className={"framer-ueongf"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"fA2Sjk1Sc"} style={{"--extracted-r6o4lv": "rgba(253, 250, 246, 0.5)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={HFZZ32egY} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-C3mmn.framer-vqkh4c, .framer-C3mmn .framer-vqkh4c { display: block; }", ".framer-C3mmn.framer-tpuiza { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-C3mmn .framer-ueongf { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-C3mmn.framer-tpuiza { gap: 0px; } .framer-C3mmn.framer-tpuiza > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-C3mmn.framer-tpuiza > :first-child { margin-left: 0px; } .framer-C3mmn.framer-tpuiza > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 49.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"HFZZ32egY":"subtitle"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerEXTb7_Z7k: React.ComponentType<Props> = withCSS(Component, css, "framer-C3mmn") as typeof Component;
export default FramerEXTb7_Z7k;

FramerEXTb7_Z7k.displayName = "Footer / Nav / Subheading";

FramerEXTb7_Z7k.defaultProps = {height: 18, width: 49.5};

addPropertyControls(FramerEXTb7_Z7k, {HFZZ32egY: {defaultValue: "Product", displayTextArea: false, title: "Subtitle", type: ControlType.String}} as any)

addFonts(FramerEXTb7_Z7k, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})